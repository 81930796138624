// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: process.env.PRODUCTION,
    nodeEnv: process.env.NODE_ENV,
    apiUrl: process.env.API_URL,
    clientId: process.env.CLIENT_ID,
    authorization: process.env.AUTHORIZATION,
    recaptchaSiteKey: process.env.RECAPTCHA_SITEKEY,
    recaptchaSecretKey: process.env.RECAPTCHA_SECRETKEY,
    // set IDLE_TIMEOUT for set expire session user login in second
    idleTimeOut: process.env.IDLE_TIMEOUT,
    auth0ClientId: process.env.AUTH0_CLIENT_ID,
    auth0Domain: process.env.AUTH0_DOMAIN,
    auth0Audience: process.env.AUTH0_AUDIENCE,
    firebase: {
        apiKey: process.env.FIREBASE_API_KEY,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.FIREBASE_DATABASE_URL,
        projectId: process.env.FIREBASE_PROJECT_ID,
        storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.FIREBASE_APP_ID,
        measurementId: process.env.FIREBASE_MEASUREMENT_ID
    },
    pricePerStar: process.env.PRICE_PER_STAR,
    gtagId: process.env.GTAG_ID,
    surveyResultWebUrl: process.env.SURVEY_RESULT_WEB_URL,
    google_maps_api_key: process.env.GOOGLE_MAPS_API_KEY
};
