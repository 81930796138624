export const PERMISSIONS = {
    AUCTION: {
        READ: 'WEB:AUCTION:READ',
        DETAIL: 'WEB:AUCTION:READ',
        CREATE: 'WEB:AUCTION:CREATE',
        DELETE: 'WEB:AUCTION:DELETE',
        UPDATE: 'WEB:AUCTION:UPDATE'
    },
    BADGE: {
        READ: 'WEB:BADGE:READ',
        DETAIL: 'WEB:BADGE:READ',
        CREATE: 'WEB:BADGE:CREATE',
        DELETE: 'WEB:BADGE:DELETE',
        UPDATE: 'WEB:BADGE:UPDATE',
        HISTORY: 'WEB:BADGE:READ'
    },
    USER: {
        READ: 'WEB:USER:READ',
        DETAIL: 'WEB:USER:READ',
        CREATE: 'WEB:USER:CREATE',
        DELETE: 'WEB:USER:DELETE',
        UPDATE: 'WEB:USER:UPDATE',
        RESELLER_READ: 'WEB:LEAD_USER:READ'
    },
    EVENT: {
        READ: 'WEB:EVENT:READ',
        DETAIL: 'WEB:EVENT:READ',
        CREATE: 'WEB:EVENT:CREATE',
        DELETE: 'WEB:EVENT:DELETE',
        UPDATE: 'WEB:EVENT:UPDATE'
    },
    ORGANIZATION: {
        READ: 'WEB:ORGANIZATION:READ',
        DETAIL: 'WEB:ORGANIZATION:READ',
        CREATE: 'WEB:ORGANIZATION:CREATE',
        DELETE: 'WEB:ORGANIZATION:DELETE',
        UPDATE: 'WEB:ORGANIZATION:UPDATE',
        // READ_SETTING: 'READ:ORGANIZATION_SETTING',
        // READ_SETTING_DETAIL: 'READ:ORGANIZATION_SETTING_DETAIL',
        // CREATE_SETTING: 'CREATE:ORGANIZATION_SETTING',
        READ_PROFILE: 'WEB:ORGANIZATION:READ',
        UPDATE_PROFILE: 'WEB:ORGANIZATION:UPDATE'
    },
    LEAD: {
        READ: 'WEB:LEAD:READ',
        DETAIL: 'WEB:LEAD:READ',
        CREATE: 'WEB:LEAD:CREATE',
        DELETE: 'WEB:LEAD:DELETE',
        UPDATE: 'WEB:LEAD:UPDATE',
        // READ_SETTING: 'READ:ORGANIZATION_SETTING',
        // READ_SETTING_DETAIL: 'READ:ORGANIZATION_SETTING_DETAIL',
        // CREATE_SETTING: 'CREATE:ORGANIZATION_SETTING',
        READ_PROFILE: 'WEB:LEAD:READ',
        UPDATE_PROFILE: 'WEB:LEAD:UPDATE'
    },
    RESELLER: {
        READ: 'WEB:ORGANIZATION:READ',
        DETAIL: 'WEB:ORGANIZATION:READ',
        CREATE: 'WEB:ORGANIZATION:CREATE',
        DELETE: 'WEB:ORGANIZATION:DELETE',
        UPDATE: 'WEB:ORGANIZATION:UPDATE',
        // READ_SETTING: 'READ:ORGANIZATION_SETTING',
        // READ_SETTING_DETAIL: 'READ:ORGANIZATION_SETTING_DETAIL',
        // CREATE_SETTING: 'CREATE:ORGANIZATION_SETTING',
        READ_PROFILE: 'WEB:ORGANIZATION:READ',
        UPDATE_PROFILE: 'WEB:ORGANIZATION:UPDATE'
    },
    PRIZE: {
        READ: 'WEB:PRIZE:READ',
        DETAIL: 'WEB:PRIZE:READ',
        CREATE: 'WEB:PRIZE:CREATE',
        DELETE: 'WEB:PRIZE:DELETE',
        UPDATE: 'WEB:PRIZE:UPDATE',
        HISTORY: 'WEB:PRIZE:READ'
    },
    SETUP: {
        POSITION_READ: 'WEB:POSITION:READ',
        STRUCTURE_READ: 'WEB:STRUCTURE:READ',
        STAR_READ: 'ORG_SUPER_ADMIN',
        HOLIDAY_READ: 'WEB:HOLIDAY:READ',
        TIME_OFF_READ: 'WEB:TIME_OFF:READ',
        WORKING_SCHEDULE_READ: 'WEB:WORKING_SCHEDULE:READ',
        APP_SETTING_READ: 'ORG_SUPER_ADMIN',
        WEB_SETTING_READ: 'ORG_SUPER_ADMIN',
        ADMIN_ROLES_READ: 'WEB:ROLE:READ',
        FEATURE_READ: 'ORG_SUPER_ADMIN',
        USER_DEVICE_READ: 'WEB:USER_DEVICE:READ',

        ADMIN_ROLES: {
            CREATE: 'WEB:ROLE:CREATE',
            UPDATE: 'WEB:ROLE:UPDATE',
            DELETE: 'WEB:ROLE:DELETE'
        },
        POSITION: {
            CREATE: 'WEB:POSITION:CREATE',
            UPDATE: 'WEB:POSITION:UPDATE',
            DELETE: 'WEB:POSITION:DELETE'
        },
        STRUCTURE: {
            CREATE: 'WEB:STRUCTURE:CREATE',
            UPDATE: 'WEB:STRUCTURE:UPDATE',
            DELETE: 'WEB:STRUCTURE:DELETE'
        },
        STAR: {
            CREATE: 'ORG_SUPER_ADMIN',
            UPDATE: 'ORG_SUPER_ADMIN',
            DELETE: 'ORG_SUPER_ADMIN'
        },
        HOLIDAY: {
            CREATE: 'WEB:HOLIDAY:CREATE',
            UPDATE: 'WEB:HOLIDAY:UPDATE',
            DELETE: 'WEB:HOLIDAY:DELETE'
        },
        TIME_OFF: {
            CREATE: 'WEB:TIME_OFF:CREATE',
            UPDATE: 'WEB:TIME_OFF:UPDATE',
            DELETE: 'WEB:TIME_OFF:DELETE'
        },
        WORKING_SCHEDULE: {
            CREATE: 'WEB:WORKING_SCHEDULE:CREATE',
            UPDATE: 'WEB:WORKING_SCHEDULE:UPDATE',
            DELETE: 'WEB:WORKING_SCHEDULE:DELETE'
        },
        APP_SETTING: {
            CREATE: 'ORG_SUPER_ADMIN',
            UPDATE: 'ORG_SUPER_ADMIN',
            DELETE: 'ORG_SUPER_ADMIN'
        },
        WEB_SETTING: {
            CREATE: 'ORG_SUPER_ADMIN',
            UPDATE: 'ORG_SUPER_ADMIN',
            DELETE: 'ORG_SUPER_ADMIN'
        },
        EMAIL_CONFIG: 'WEB:EMAIL:CREATE',
        TRANSLATION: {
            READ: 'WEB:TRANSLATION:READ',
            CREATE: 'WEB:TRANSLATION:CREATE',
            UPDATE: 'WEB:TRANSLATION:UPDATE',
            DELETE: 'WEB:TRANSLATION:DELETE'
        },
        ROOM: {
            READ: 'WEB:ROOM:READ',
            CREATE: 'WEB:ROOM:CREATE',
            UPDATE: 'WEB:ROOM:UPDATE',
            DELETE: 'WEB:ROOM:DELETE'
        },
        BRANCH: {
            READ: 'WEB:BRANCH:READ',
            CREATE: 'WEB:BRANCH:CREATE',
            UPDATE: 'WEB:BRANCH:UPDATE',
            DELETE: 'WEB:BRANCH:DELETE'
        },
    },
    TEAM: {
        READ: 'WEB:TEAM:READ',
        DETAIL: 'WEB:TEAM:READ',
        CREATE: 'WEB:TEAM:CREATE',
        DELETE: 'WEB:TEAM:DELETE',
        UPDATE: 'WEB:TEAM:UPDATE'
    },
    QUIZ: {
        READ: 'WEB:QUIZ:READ',
        UPDATE: 'WEB:QUIZ:UPDATE'
    },
    ATTENDANCE: {
        READ: 'WEB:ATTENDANCE:READ',
        DETAIL: 'WEB:ATTENDANCE:DETAIL',
        CREATE: 'WEB:ATTENDANCE:CREATE',
        DELETE: 'WEB:ATTENDANCE:DELETE',
        UPDATE: 'WEB:ATTENDANCE:UPDATE'
    },
    DASHBOARD_REPORT: {
        READ: 'WEB:DASHBOARD_REPORT:READ',
        CREATE: 'WEB:DASHBOARD_REPORT:CREATE'
    },
    TIME_OFF: {
        READ: 'WEB:TIME_OFF:READ',
        DETAIL: 'WEB:TIME_OFF:READ',
        CREATE: 'WEB:TIME_OFF:CREATE',
        DELETE: 'WEB:TIME_OFF:DELETE',
        UPDATE: 'WEB:TIME_OFF:UPDATE'
    },
    ANNOUNCEMENT: {
        READ: 'WEB:POST:READ',
        DETAIL: 'WEB:POST:READ',
        CREATE: 'WEB:POST:CREATE',
        DELETE: 'WEB:POST:DELETE',
        UPDATE: 'WEB:POST:UPDATE'
    },
    BILLING: {
        READ: 'WEB:BILLING:READ',
        SUBSCRIPTION: {
            READ: 'WEB:BILLING_SUBSCRIPTION:READ'
        },
        PROFILE: {
            READ: 'WEB:BILLING_PROFILE:READ'
        },
        METHOD: {
            READ: 'WEB:BILLING_METHOD:READ'
        },
        ADDRESS: {
            READ: 'WEB:BILLING_ADDRESS:READ'
        },
        ADMIN: {
            READ: 'WEB:BILLING_ADMIN:READ',
            CREATE: 'WEB:BILLING_ADMIN:CREATE'
        },
        INVOICE: {
            READ: 'WEB:BILLING_INVOICE:READ'
        }
    },
    SYSTEM_ADMIN: {
        READ: 'WEB:ORGANIZATION:READ',
        CREATE: 'WEB:ORGANIZATION:READ',
        UPDATE: 'WEB:ORGANIZATION:READ',
        DELETE: 'WEB:ORGANIZATION:READ'
    },
    ORG_SUPER_ADMIN: 'ORG_SUPER_ADMIN'

};
